import {Box, Button, Grid, InputAdornment, Stack, Typography} from "@mui/material";
import {FieldArray, useFormikContext} from "formik";
import {useCallback, useMemo} from "react";
import NumberField from "../../components/Formik/NumberField";
import Checkbox from "../../components/Formik/Checkbox";

const Deliveries = ({catalog}) => {
    const {values, setFieldValue} = useFormikContext();

    const currentDeliveries = useCallback(() => {
        return values.declaration.deliveries.map(el => {
            const article = catalog.articles?.find(article => article['@id'] === el.article);
            if (article.code === '12') {
                return {
                    ...el,
                    display: values.declaration.contractType.includes('Lin') ? 'block' : 'none',
                    disabled: false
                }
            } else {
                return {...el, display: !!el.delivery ? 'block' : 'none', disabled: true}
            }
        });
    }, [values.declaration.contractType]);

    useMemo(() => {
        setFieldValue('closedContract', values.declaration.deliveries.every(el => el.isSoldOut === true));
    }, [values.declaration.deliveries]);

    return (
        <Grid container spacing={3} sx={{mt: 1}}>
            <Grid xs={12} item>
                <Stack spacing={1} direction={'row'}>
                    <Typography color="primary" fontWeight="bold">
                        Droit à livrer
                    </Typography>
                    <Typography variant="caption">
                        (Les droits à livrer sont calculés après chaque enregistrement)
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={6} item>
                <FieldArray name={'declaration.deliveries'}>
                    <Stack spacing={2}>
                        {currentDeliveries()
                            .map((el, i) => {
                                const articleLabel = catalog.articles?.find(article => article['@id'] === el.article)?.label;
                                return (
                                    <Stack key={i} spacing={2} direction={'row'} alignItems={'center'}>
                                        <NumberField
                                            sx={{display: el.display}}
                                            name={`declaration.deliveries[${i}].delivery`}
                                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                            label={`Droit à livrer ${articleLabel}`}
                                            InputProps={{
                                                endAdornment: <InputAdornment
                                                    position="start">Tonnes</InputAdornment>,
                                            }}
                                            className={'input-deliveries'}
                                            disabled={el.disabled}
                                            helperText={el.hasInvoiceArticle && `Recalcul du droit à livrer impossible pour ${articleLabel} : une facturation existe pour cet article.`}
                                            asString
                                            nullIfEmpty
                                        />
                                        <Box component={'span'} sx={{fontWeight: 'bold', whiteSpace: 'nowrap', display: el.display}} className={'typography-bold'}>
                                            <Checkbox name={`declaration.deliveries[${i}].isSoldOut`}
                                                      label={'Soldé'}
                                            />
                                        </Box>
                                    </Stack>
                                )
                            })}
                    </Stack>
                </FieldArray>
            </Grid>
        </Grid>
    );
}

export default Deliveries;