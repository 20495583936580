import {Divider, Grid} from "@mui/material";
import DateNative from "../../components/Formik/Date/Native";
import NumberField from "../../components/Formik/NumberField";
import HarvestStrawTable from "./HarvestStrawTable";
import StockStrawTable from "./StockStrawTable";
import {FieldArray, useFormikContext} from "formik";
import MemberDetailHead from "../../feature/MemberDetailHead";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MultipleCheckbox from "../../components/Formik/MultipleCheckbox";
import Deliveries from "./Deliveries";
import {useContractContext} from "../../context/ContractContext";
import {useUserContext} from "../../context/UserContext";
import {ROLES} from "../../constants/roles";
import {useMemo} from "react";

const DeclarationPage = () => {
    const {values} = useFormikContext();
    const {catalog} = useContractContext();
    const {isGranted} = useUserContext();
    const {
        rouieBeatenTonnage,
        rouieUnbeatenTonnage,
        rouieSemiBeatenTonnage,
        rouieSemiUnbeatenTonnage,
        rouieBeatenArea,
        rouieUnbeatenArea,
        rouieSemiBeatenArea,
        rouieSemiUnbeatenArea
    } = values;


    function createData(name, beatenArea, unbeatenArea, beatenTonnage, unBeatenTonnage) {
        return {name, beatenArea, unbeatenArea, beatenTonnage, unBeatenTonnage};
    }

    const rows = [
        createData('Rouie', rouieBeatenArea, rouieUnbeatenArea, rouieBeatenTonnage, rouieUnbeatenTonnage),
        createData('Semi-Rouie', rouieSemiBeatenArea, rouieSemiUnbeatenArea, rouieSemiBeatenTonnage, rouieSemiUnbeatenTonnage),
    ];

    const totalRouie = useMemo(() => +rouieBeatenTonnage + +rouieUnbeatenTonnage + +rouieBeatenArea + +rouieUnbeatenArea,
        [rouieBeatenTonnage, rouieUnbeatenTonnage, rouieBeatenArea, rouieUnbeatenArea]);
    const totalSemiRouie = useMemo(() => +rouieSemiBeatenTonnage + +rouieSemiUnbeatenTonnage + +rouieSemiBeatenArea + +rouieSemiUnbeatenArea,
        [rouieSemiBeatenTonnage, rouieSemiUnbeatenTonnage, rouieSemiBeatenArea, rouieSemiUnbeatenArea]);

    const contractType = values.declaration.contractType;
    if (totalRouie > 0 && contractType.indexOf('Rouie') === -1) {
        contractType.push('Rouie');
    } else if (totalRouie === 0) {
        const index = contractType.indexOf('Rouie');
        delete(contractType[index]);
    }

    if (totalSemiRouie > 0 && contractType.indexOf('Semi rouie') === -1) {
        contractType.push('Semi rouie');
    } else if (totalSemiRouie === 0) {
        const index = contractType.indexOf('Semi rouie');
        delete(contractType[index]);
    }

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                <MemberDetailHead memberId={values.member}/>
                <Divider/>
                <Grid container spacing={3} sx={{mt: 1}}>
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'contractToProduce'} label={'Contrats à produire'} disabled/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField name={'declaration.endDateEngagement'} label={`Dernière année d'engagement`}
                                     disabled/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DateNative name={'declaration.declarationDate'} label={`Date déclaration`}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField
                            name={'year'}
                            label={'Année'}
                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'declaration.capitalShares'} label={'Parts Capital'} disabled/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'declaration.capital'} label={'Capital'} disabled/>
                    </Grid>
                    {/*Surface*/}
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'declaration.unbeatenArea'} label={'Non battu'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'declaration.beatenArea'} label={'Battu'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberField asString name={'declaration.totalArea'} label={'Total'}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell align="left">Surface battue</TableCell>
                                        <TableCell align="left">Surface non battue</TableCell>
                                        <TableCell align="left">Tonnage battu</TableCell>
                                        <TableCell align="left">Tonnage non battu</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isGranted(ROLES.ADMIN) && rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.beatenArea}</TableCell>
                                            <TableCell align="left">{row.unbeatenArea}</TableCell>
                                            <TableCell align="left">{row.beatenTonnage}</TableCell>
                                            <TableCell align="left">{row.unBeatenTonnage}</TableCell>
                                        </TableRow>
                                    ))}
                                    {isGranted(ROLES.ADMIN) && ['rouie', 'rouieSemi'].map(e => (
                                        <TableRow
                                            key={e}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {e === 'rouie' ? 'Rouie' : 'Semi-Rouie'}
                                            </TableCell>
                                            <TableCell align="left"><NumberField asString nullIfEmpty
                                                                                 name={`${e}BeatenArea`}/></TableCell>
                                            <TableCell align="left"><NumberField asString nullIfEmpty
                                                                                 name={`${e}UnbeatenArea`}/></TableCell>
                                            <TableCell align="left"><NumberField asString nullIfEmpty
                                                                                 name={`${e}BeatenTonnage`}/></TableCell>
                                            <TableCell align="left"><NumberField asString nullIfEmpty
                                                                                 name={`${e}UnbeatenTonnage`}/></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{mt: 1}}>
                    <Grid item xs={12}>
                        <MultipleCheckbox name={'declaration.contractType'}
                                          legend={'Type de contrat'}
                                          options={catalog.contractTypes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldArray
                            name={'declaration.declarationStrawHarvests'}
                            component={HarvestStrawTable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldArray
                            name={'declaration.declarationStrawStocks'}
                            component={StockStrawTable}
                        />
                    </Grid>
                </Grid>
                <Deliveries catalog={catalog}/>
            </Grid>
        </>
    );
}

export default DeclarationPage;
