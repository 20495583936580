import {Box, Button as MuiButton, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@mui/material";
import Select from "../../../components/Formik/Select";
import SelectYear from "../../../components/Formik/SelectYear";
import DateNative from "../../../components/Formik/Date/Native";
import MultipleCheckbox from "../../../components/Formik/MultipleCheckbox";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import {ROLES} from "../../../constants/roles";
import {chenevisInvoiceService} from "../../../services/chenevisInvoice.service";
import {memberService} from "../../../services/member.service";
import {run} from "../../../utils/services";
import snackBar from "../../../components/SnackBar";
import download from "../../../components/Download";
import {Form, Formik} from "formik";
import {exportService} from "../../../services/export.service";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {useDialog} from "../../../feature/DialogProvider";

const ExportDialog = ({catalog, isGranted, title}) => {

    const [, closeDialog] = useDialog();

    const INITIAL_FORM_STATE = {
        filters: [],
        number: null,
        date_start: null,
        date_end: null,
        from_member_id: null,
        to_member_id: null,
        from_invoice_id: null,
        to_invoice_id: null,
        article_id: null,
        harvest_year: null
    }

    const availableInvoiceTypes = [
        "Liste des factures",
        "Liste des 1ers acomptes",
        "Liste des 2ème acomptes",
        "Liste des soldes",
        "Facture 1ers acomptes",
        "Facture 2ème acomptes",
        "Factures soldes"
    ];

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(exportService.exportChenevisInvoice, values, {setFieldError, setSubmitting})
                    .then((response) => {
                        snackBar(`Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.`, 'success');
                        download(response.responseData.pdf, true);
                        return;
                    });
            }}
        >
            <Form>
                <DialogTitle className={title ? "headerModal" : null}>
                    <div><h3>{title}</h3></div>
                </DialogTitle>
                <DialogContent className="contentModal">
                    <Box sx={{my: 2}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <MultipleCheckbox
                                    name={'filters'}
                                    legend={'Type d\'export'}
                                    options={catalog.exportInvoicesTypes.filter(el => availableInvoiceTypes.includes(el))}
                                    sxLabel={{width: '200px'}}
                                />
                            </Grid>

                            {isGranted(ROLES.ADMIN) && <>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'from_invoice_id'}
                                        label={'De la facture n°'}
                                        filterName={'number'}
                                        request={chenevisInvoiceService.getAllChevenisInvoices}
                                        getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'to_invoice_id'}
                                        label={'A la facture n°'}
                                        filterName={'number'}
                                        request={chenevisInvoiceService.getAllChevenisInvoices}
                                        getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'from_member_id'}
                                        label={'De l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'ASC'}}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'to_member_id'}
                                        label={'A l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={12} md={6}>
                                <DateNative name={'date_start'} label={'Du...'} nullIfEmpty/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateNative name={'date_end'} label={'Au...'} nullIfEmpty/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectYear
                                    name={'harvest_year'}
                                    label={'Année'}
                                    reverse
                                    nullIfEmpty
                                    start={isGranted(ROLES.MEMBER) ? 2023 : 2021}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <Select name={'article_id'} label={'Article'}>
                                    {catalog.articles.map((el, i) => {
                                        return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions className="footerModal">
                    <SubmitButton>
                        Générer
                    </SubmitButton>
                    <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                </DialogActions>
            </Form>
        </Formik>
    )
}

export default ExportDialog