import {Formik, Form, useFormikContext} from "formik";
import {
    Dialog, DialogContent,
    Button as MuiButton,
    Grid,
    MenuItem,
    DialogTitle,
} from "@mui/material";
import * as Yup from "yup";
import Button from "../../components/Formik/SubmitButton";
import Select from "../../components/Formik/Select";
import NumberField from "../../components/Formik/NumberField";
import {useEffect, useState} from "react";
import {run} from "../../utils/services";
import {strawService} from "../../services/straw.service";
import {hydraService} from "../../utils/hydra";
import {ROLES} from "../../constants/roles";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {contractService} from "../../services/contract.service";
import {useParams} from "react-router-dom";


const AddEditStrawTable = ({rows, replace, push, toUpdatedData, title, colorDrivers, name}) => {

    const {values: {declaration}, setValues: setContractValues} = useFormikContext();
    const {id} = useParams();

    const [open, setOpen] = useState(false);

    const [formState, setFormState] = useState(null);

    const FORM_VALIDATION_HISTORY = Yup.object().shape({});

    useEffect(() => {
        if (toUpdatedData) {
            const article = colorDrivers.find(el => el['@id'] === toUpdatedData.colorDriver)?.article['@id'];
            handleOpenDialog({...toUpdatedData, article});
        }
    }, [toUpdatedData])

    const handleCloseDialog = () => {
        setOpen(false);
        setFormState(null);
    };

    const handleOpenDialog = (data) => {
        setOpen(true);
        setFormState(data);
    };

    const createRow = (values, props) => {
        const service = (name === 'declaration.declarationStrawHarvests') ? strawService.addDeclarationStrawHarvest : strawService.addDeclarationStrawStock;
        run(service, values, {...props})
            .then((response) => {
                reloadContract().then(() => {
                    handleCloseDialog();
                });
            });
    };

    const updateRow = (values, props) => {
        const service = (name === 'declaration.declarationStrawHarvests') ? strawService.editDeclarationStrawHarvest : strawService.editDeclarationStrawStock;
        const findIndex = rows.findIndex(el => el['@id'] === values['@id']);
        if (findIndex >= 0) {
            run(service, {...values, id: hydraService.getIdFromIri(values['@id'])}, {...props})
                .then(() => {
                    reloadContract().then(() => {
                        handleCloseDialog();
                    });
                });
        }
    };

    const reloadContract = () => {
        return run(contractService.getContract, id)
            .then((response) => {
                setContractValues(response.responseData);
            });
    }

    const FormObserver = () => {
        const {values, setFieldValue} = useFormikContext();

        useEffect(() => {
            if (!Boolean(values.balesNumber || values.tonnage)) {
                return;
            }
            const average = +values.balesNumber ? (+values.tonnage / +values.balesNumber).toFixed(2) : null;
            setFieldValue('averageBaleWeight', average);
        }, [values.balesNumber, values.tonnage]);
    }

    return (
        <>
            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                <MuiButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        const form = {
                            article: null,
                            declaration: declaration['@id'],
                            averageBaleWeight: null,
                            balesNumber: null,
                            colorDriver: null,
                            tonnage: null,
                        };
                        handleOpenDialog(form);
                    }}
                >
                    Ajouter
                </MuiButton>
            </ProtectedComponent>
            <Dialog open={Boolean(open && formState)} onClose={handleCloseDialog}>
                <DialogTitle className={"headerModal"}>
                    <div><h3>{title}</h3></div>
                </DialogTitle>
                <DialogContent className={'contentModal'}>
                    {formState && (
                        <Formik
                            initialValues={{...formState}}
                            validationSchema={FORM_VALIDATION_HISTORY}
                            onSubmit={(values, {setFieldError, setSubmitting}) => {
                                if (values['id'] || values['@id']) {
                                    updateRow(values, {setFieldError, setSubmitting});
                                } else {
                                    createRow(values, {setFieldError, setSubmitting});
                                }
                            }}
                        >
                            {({values}) => (
                                <Form>
                                    <FormObserver/>
                                    <Grid container spacing={2} className="Grid-container">
                                        <Grid item xs={12} md={6}>
                                            <Select name={"colorDriver"} label={"Couleur"}>
                                                <MenuItem value={null}><em>Aucune</em></MenuItem>
                                                {colorDrivers.map((el, i) => {
                                                    const initals = el.article.label.match(/(?<=\b)\w/iug).join('');
                                                    return (
                                                        <MenuItem key={i} value={el['@id']}>
                                                            {el.label} {title !== 'Stock paille' ? `(${initals})` : ''}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <NumberField
                                                name={"tonnage"}
                                                asString
                                                label={"Tonnage"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <NumberField
                                                name={"balesNumber"}
                                                asString
                                                label={"Nombre de balles"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <NumberField
                                                name={"averageBaleWeight"}
                                                asString
                                                label={"Poids moyen des balles"}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid className={"footerModalArticle"} item xs={12} md={12} rowSpacing={2}
                                          display='flex' flexDirection='row' justifyContent='flex-end'>
                                        <Button>{formState["@id"] ? 'Modifier' : 'Ajouter'}</Button>
                                        <MuiButton
                                            onClick={() => {
                                                handleCloseDialog();
                                            }}
                                        >
                                            Annuler
                                        </MuiButton>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
export default AddEditStrawTable;