import {Button as MuiButton, Grid, Stack} from "@mui/material";
import {useState} from "react";
import {ticketService} from "../../../services/ticket.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {
    DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import {run} from "../../../utils/services";
import MemberFilter from "../../../components/Filters/VisibleDialog/MemberFilter";
import ArticleTypeFilter from "../../../components/Filters/VisibleDialog/ArticleTypeFilter";
import DepositFilter from "../../../components/Filters/VisibleDialog/DepositFilter";
import snackBar from "../../../components/SnackBar";
import {Formik} from "formik";
import {DateNative} from "../../../components/Formik";
import {supplyService} from "../../../services/supply.service";
import YearFilter from "../../../components/Filters/VisibleDialog/YearFilter";
import {hydraService} from "../../../utils/hydra";
import {useDialog} from "../../../feature/DialogProvider";
import Confirmation from "../../../feature/Confirmation";

const VisibleDialog = ({catalog, title}) => {

    const [, closeDialog] = useDialog();

    const INITIAL_FORM_STATE = {
        pickup_date: null,
        ids: [],
    }

    const [filters, setFilters] = useState({});

    const [selection, setSelection] = useState([]);

    const columns = [
        {field: 'number', headerName: 'N° ticket', flex: 1},
        {field: 'year', headerName: 'Année', flex: 1},
        {field: 'company', headerName: 'Adhérent', flex: 1, valueGetter: (params) => params.row?.company},
        {
            field: 'articleTypeLabel',
            headerName: 'Type article',
            flex: 1,
            valueGetter: (params) => params.row?.articleTypeLabel
        },
        {field: 'deposit', headerName: 'Dépôt', flex: 1},
    ];

    const handleSubmit = (submitForm) => {
        if (selection.length === 0) {
            return;
        }
        submitForm();
    }

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                values = {...values, ids: selection.map(iri => parseInt(hydraService.getIdFromIri(iri)))}
                run(supplyService.validateSupply, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar('Tickets sélectionnés mis à jour.', 'success');
                        closeDialog();
                        return;
                    });
            }}
        >
            {({submitForm}) => (
                <>
                    <DialogTitle className={title ? "headerModal" : null}>
                        <div><h3>{title}</h3></div>
                    </DialogTitle>
                    <DialogContent className="contentModal">
                        <Grid item xs={8} className="Grid">
                            <Stack spacing={1} direction={'row'} paddingTop={3}>
                                <YearFilter filters={filters} setFilters={setFilters}/>
                                <MemberFilter filters={filters} setFilters={setFilters}/>
                                <ArticleTypeFilter filters={filters} setFilters={setFilters}
                                                   name={'articleType.code'}
                                                   articleTypeList={catalog.articleTypeList}/>
                                <DepositFilter filters={filters} setFilters={setFilters}
                                               depositList={catalog.depositList}/>
                                {selection.length > 0 &&
                                    <DateNative name={'pickup_date'} label={'Date d\'enlèvement'} required
                                                nullIfEmpty/>}
                            </Stack>
                        </Grid>
                        <MuiTableAsync
                            checkboxSelection
                            columns={columns}
                            request={ticketService.getNotVisibleSupplyTicket}
                            filters={filters}
                            customPagination
                            keepNonExistentRowsSelected
                            selectionModel={selection}
                            onSelectionModelChange={setSelection}
                        />
                    </DialogContent>
                    <DialogActions className="footerModal">
                        <Confirmation
                            buttonLabel={'Lancer le traitement'}
                            disabled={selection.length === 0}
                            modalTitle={'Confirmer la sélection'}
                            modalContent={selection.length > 0 ? `Mettre à jour les ${selection.length} tickets sélectionnés ?` : 'Aucun ticket sélectionné.'}
                            handleConfirm={() => handleSubmit(submitForm)}
                            startIcon={''}
                            color={'primary'}
                        />
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </>
            )}
        </Formik>
    );
}

export default VisibleDialog