import {MenuItem} from "@mui/material";
import SelectWrapper from "../Select";
import Range from "../../../utils/range";

/**
 * This composant is mapped with SelectWrapper from Formik
 * All options of Formik/SelectWrapper can be used with this component
 *
 *
 * @param name
 * @param start
 * @param end
 * @param step
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */

const SelectYearWrapper = ({name, start = 2021, end = new Date().getFullYear(), step = 1, reverse = false, placeholder = 'Aucune', ...otherProps}) => {

    const range = Range(start, end, step);
    if (reverse) {
        range.reverse();
    }

    return (
        <SelectWrapper name={name} {...otherProps}>
            {placeholder && <MenuItem disabled={otherProps.disabled} value={null}>{placeholder}</MenuItem>}
            {range.map((el, i) => (
                <MenuItem disabled={otherProps.disabled} key={i} value={el}>{el}</MenuItem>
            ))}
        </SelectWrapper>
    );
}

export default SelectYearWrapper;