import {useEffect, useState} from "react";
import {Box, Grid, Stack, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DetailPage from "./DetailPage";
import DeclarationPage from "./DeclarationPage";
import Button from "../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../Loader";
import {contractService} from "../../services/contract.service";
import snackBar from "../../components/SnackBar";
import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import {STRAW_CODES} from "../../constants/types";
import {run} from "../../utils/services";
import {useUserContext} from "../../context/UserContext";
import FormDisabler from "../../components/Formik/ThemeFormikDisablerProvider";
import {ContractContextProvider, useContractContext} from "../../context/ContractContext";
import BackButton from "../../components/BackButton";

const ContractPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(!!id);
    const INITIAL_FORM_STATE = {
        member: null,
        closedContract: false,
        harvestYearClosed: false,
        contractDate: null,
        year: null,
        hectaresNumber: null,
        rouieEmblavedArea: '',
        rouieBeatenArea: '',
        rouieUnbeatenArea: '',
        rouieSemiEmblavedArea: '',
        rouieSemiBeatenArea: '',
        rouieSemiUnbeatenArea: '',
        noRouieEmblavedArea: null,
        noRouieBeatenArea: null,
        noRouieUnbeatenArea: null,
        farmingType: 'Conventionnel',
        biologicalCertificateNumber: '',
        outEngagement: false,
        contractToProduce: '',
        basicEngagement: null,
        badWeatherCancellation: null,
        declaration: {
            member: null,
            contractToProduce: null,
            endDateEngagement: null,
            declarationDate: null,
            capitalShares: null,
            capital: null,
            unbeatenArea: null,
            beatenArea: null,
            totalArea: null,
            contractType: ['Non rouie'],
            declarationStrawHarvests: [],
            declarationStrawStocks: [],
            deliveries: []
        },
        calculateStrawDelivered: '',
        calculateStrawStillDelivered: '',
        tonnages: [],
        rouieBeatenTonnage: '',
        rouieUnbeatenTonnage: '',
        rouieSemiBeatenTonnage: '',
        rouieSemiUnbeatenTonnage: '',
        hasSubscription: false,
        strawTickets: []
    }
    const FORM_VALIDATION = Yup.object().shape({});
    const [data, setData] = useState(INITIAL_FORM_STATE);

    function createTonnages(articles = []) {
        return articles.filter(el => STRAW_CODES.find(sc => sc.code === el.code))
            .map(el => ({
                article: el['@id'],
                maxTonnage: null,
            }));
    }

    function createDeliveries(articles = []) {
        return articles.filter(el => STRAW_CODES.find(sc => sc.code === el.code))
            .map(el => ({
                article: el['@id'],
                delivery: null,
            }));
    }

    useEffect(() => {
        if (!!id) {
            run(contractService.getContract, id)
                .then((response) => {
                    setData(response.responseData);
                    setLoading(false);
                })
        }
    }, [id]);


    const [value, setValue] = useState('0');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const Tablr = () => {
        const {catalog} = useContractContext();
        return (
            <TabContext value={value}>
                <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Détails" value={'0'}/>
                    <Tab label="Déclaration" value={'1'} disabled={catalog.isLoading}/>
                </TabList>
                <TabPanel value={'0'} sx={{padding: 2}}>
                    <FormDisabler disabled={isGranted(ROLES.MEMBER)}>
                        <DetailPage/>
                    </FormDisabler>
                </TabPanel>
                <TabPanel value={'1'} sx={{padding: 2}}>
                    <FormDisabler disabled={isGranted(ROLES.MEMBER)}>
                        <DeclarationPage/>
                    </FormDisabler>
                </TabPanel>
            </TabContext>
        )
    }

    return (
        loading ? <Loader/> :
                <Formik
                    initialValues={data}
                    validationSchema={FORM_VALIDATION}
                    enableReinitialize={true}
                    onSubmit={(values, {setFieldError, setFieldValue, setSubmitting, setValues}) => {
                        if (!id) {
                            run(contractService.addContract, values, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                    navigate(response.responseData['@id']);
                                });
                        } else {
                            run(contractService.editContract, {id, ...values}, {setFieldError, setSubmitting})
                                .then((response) => {
                                    snackBar('Enregistrement effectué');
                                    setFieldValue('declaration.deliveries', response.responseData?.declaration?.deliveries);
                                    console.log(response.responseData);
                                    navigate(response.responseData['@id']);
                                })
                        }
                    }}>
                    <Form>
                        <Grid container spacing={3} className="Grid Grid-root">
                            <Grid item xs={6} className="Grid"/>
                            <Grid container item xs={6} justifyContent={'flex-end'} className="Grid">
                                <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                    <Stack spacing={1} direction={'row'}>
                                        <Button variant={'outlined'}/>
                                        <BackButton/>
                                    </Stack>
                                </ProtectedComponent>
                            </Grid>
                        </Grid>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <ContractContextProvider>
                                <Tablr/>
                            </ContractContextProvider>
                        </Box>
                    </Form>
                </Formik>
    );
}

export default ContractPage;