import snackBar from "../../components/SnackBar";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Button as MuiButton,
} from "@mui/material";
import {Form, Formik} from "formik";
import SubmitButton from "../../components/Formik/SubmitButton";
import * as Yup from "yup";
import {useDialog} from "../../feature/DialogProvider";
import MuiTableAsync from "../../components/Mui/DataGridAsync";
import _ from "lodash";
import {useMemo} from "react";
import {invoiceService} from "../../services/invoice.service";
import {run} from "../../utils/services";
import {toLocaleDate} from "../../utils/i18n";
import DownloadStream from "../../components/DownloadStream";

const ComptabilisationDialog = ({filters = {}}) => {
    const INITIAL_FORM_STATE = {}
    const FORM_VALIDATION = Yup.object().shape({});

    const [, closeDialog] = useDialog();

    const columns = useMemo(() => (
            [
                {
                    field: 'accountingJournal',
                    headerName: 'Journal',
                    flex: 1,
                    valueGetter: (params) => _.get(params.row, params.field)
                },
                {
                    field: 'invoiceDate',
                    headerName: 'Date de la facture',
                    flex: 1,
                    valueFormatter: (params) => toLocaleDate(params.value)
                },
                {
                    field: 't',
                    headerName: 'T',
                    flex: .2,
                },
                {
                    field: 'account',
                    headerName: 'Compte',
                    flex: 1.8,
                },
                {
                    field: 'label',
                    headerName: 'Libellé',
                    flex: 1
                },
                {
                    field: 'dc',
                    headerName: 'D/C',
                    flex: 1
                },
                {
                    field: 'amount',
                    headerName: 'Montant',
                    flex: 1,
                    align: 'right'
                },
                {
                    field: 'number',
                    headerName: 'Pièce',
                    flex: 1,
                },
                {
                    field: 'section',
                    headerName: 'Section',
                    flex: 1,
                },
                {
                    field: 'expiryDate',
                    headerName: 'Echéance',
                    flex: 1,
                    valueFormatter: (params) => toLocaleDate(params.value)
                },
                {
                    field: 'quantity',
                    headerName: 'Quantité',
                    flex: 1,
                    align: 'right'
                },
            ])
        , []);

    const handleEdition = () => {
        run(invoiceService.generateSageEdition, filters)
            .then((response) => {
                DownloadStream(response.responseData, 'application/pdf', 'sage_edition_');
            });
    }

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={INITIAL_FORM_STATE}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(invoiceService.generateSage, filters, {setFieldError, setSubmitting})
                    .then((response) => {
                        snackBar('Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.');
                        // download(response.responseData.file, true);
                        closeDialog();
                    });
            }}
        >
            <Form>
                <DialogTitle className="headerModal">
                    <div><h3>Comptabilisation - Facture non comptabilisées</h3></div>
                </DialogTitle>
                <DialogContent className={"contentModal"}>
                    <MuiTableAsync
                        columns={columns}
                        request={invoiceService.getAllComptabilisationInvoices}
                        filters={filters}
                        customPagination
                        getRowId={() => {
                            return _.uniqueId();
                        }}
                    />
                </DialogContent>
                <DialogActions className={'footerModal'}>
                    <SubmitButton>
                        Comptabilisation
                    </SubmitButton>
                    <MuiButton color={'info'} variant={'contained'} onClick={handleEdition}>Edition</MuiButton>
                    <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                </DialogActions>
            </Form>
        </Formik>
    );
}

export default ComptabilisationDialog;