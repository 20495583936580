import {run} from "../../../utils/services";
import {Divider, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import {
    DateNative, Autocomplete, NumberField,
    SelectYear, AutocompleteAsync,
    ThemeFormikDisablerProvider as FormDisabler
} from "../../../components/Formik";
import * as Yup from "yup";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../Loader";
import {chenevisService} from "../../../services/chenevis.service";
import {siloService} from "../../../services/silo.service";
import {memberService} from "../../../services/member.service";
import {articleService} from "../../../services/article.service";
import {hydraService} from "../../../utils/hydra";
import snackBar from "../../../components/SnackBar";
import MemberDetailHead from "../../../feature/MemberDetailHead";
import {ROLES} from "../../../constants/roles";
import {chenevisHumidityService} from "../../../services/chenevisHumidity.service";
import Quantity from "./Quantity";
import {ARTICLE_TYPE_CODES} from "../../../constants/types";
import {useUserContext} from "../../../context/UserContext";
import NavigationButtons from "../NavigationButtons";
import {exportService} from "../../../services/export.service";
import {ticketService} from "../../../services/ticket.service";
import _ from "lodash";
import ModificationComment from "../ModificationComment";
import {useDialog} from "../../../feature/DialogProvider";

const ChenevisPage = () => {
    const {id} = useParams();
    const {isGranted} = useUserContext();
    const navigate = useNavigate();
    const INITIAL_FORM_STATE = {
        ticketDate: null,
        harvestYear: null,
        silo: null,
        article: null,
        quantityDelivered: "",
        humidity: "",
        impurity: "",
        impurityGreenGrain: "",
        impuritySproutedGrain: "",
        impurityOther: "",
        member: null,
        blocked: false,
        canBeRemove: true,
        modificationComment: null
    };
    const FORM_VALIDATION = Yup.object().shape({});

    const [loading, setLoading] = useState(!!id);
    const [catalog, setCatalog] = useState({
        silos: [],
        articles: [],
        humidities: []
    });
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [memberSelected, setMemberSelected] = useState(null);
    const [openDialog] = useDialog();


    useEffect(() => {
        Promise.all([
            run(siloService.getAllSilos, {pagination: false}),
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.GRAINES}),
            run(chenevisHumidityService.getAllChevenisHumidities, {pagination: false}),
        ]).then((values) => {
            let [silos, articles, humidities] = values.map((el) => el.responseData);
            silos = hydraService.getMembers(silos);
            articles = hydraService.getMembers(articles);
            humidities = hydraService.getMembers(humidities);
            setCatalog({silos, articles, humidities});

            if (!!id) {
                run(chenevisService.getChenevis, id).then((response) => {
                    setData(response.responseData);
                    run(memberService
                        .getMember, hydraService.getIdFromIri(response.responseData.member))
                        .then((response) => {
                            setMemberSelected(response.responseData);
                            setLoading(false);
                        });
                });
            }
        });
        return () => null;
    }, [id]);

    return loading ? (
        <Loader/>
    ) : (
        <>
            <Formik
                initialValues={data}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    if (id && !_.isEqual(data, values)) {
                        openDialog({
                            children: <ModificationComment request={chenevisService.editChenevis}
                                                           data={values}
                                                           setData={setData}
                            />
                        })
                        return;
                    }

                    if (!id) {
                        run(chenevisService.addChenevis, values, {setFieldError, setSubmitting})
                            .then((response) => {
                                snackBar("Enregistrement effectué");
                                navigate(response.responseData['@id']);
                            });
                    } else {
                        run(chenevisService.editChenevis, {id, ...values}, {setFieldError, setSubmitting})
                            .then(() => {
                                snackBar("Enregistrement effectué");
                            });
                    }
                }}
            >
                <Form>
                    <Grid container spacing={3} className="Grid Grid-root">
                        <Grid item xs={6} className="Grid"></Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            justifyContent={"flex-end"}
                            className="Grid"
                        >
                            <NavigationButtons putRequest={chenevisService.editChenevis}
                                               exportRequest={exportService.exportChenevisTicket}
                                               removeRequest={chenevisService.removeChenevis}/>
                        </Grid>
                    </Grid>
                    <FormDisabler disabled={data.blocked || isGranted(ROLES.MEMBER)}>
                        <Grid
                            container
                            spacing={3}
                            columnSpacing={{xs: 1, sm: 2, md: 3}}
                            className="Grid-container"
                        >
                            {memberSelected &&
                                <Grid container spacing={3} className="Grid Grid-root">
                                    <Grid item xs={12} className="Grid">
                                        <MemberDetailHead member={memberSelected}/>
                                    </Grid>
                                    <Divider/>
                                </Grid>}
                            <Grid item xs={12} md={6}>
                                <DateNative name={"ticketDate"} label={"Date de bon"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectYear
                                    name={'harvestYear'}
                                    label={'Année'}
                                    end={new Date().getFullYear()}
                                    reverse
                                    nullIfEmpty
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    name={"silo"}
                                    label={"Silo"}
                                    options={catalog.silos}
                                    getOptionLabel={(option) => `${option.name}`}
                                    isOptionEqualToValue={(option, value) =>
                                        option["@id"] === value["@id"]
                                    }
                                    hydra
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    name={"article"}
                                    label={"Article"}
                                    options={catalog.articles}
                                    isOptionEqualToValue={(option, value) =>
                                        option["@id"] === value["@id"]
                                    }
                                    hydra
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberField
                                    asString
                                    name={"quantityDelivered"}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                    label={"Qté livrée brute"}
                                    decimal={3}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberField
                                    asString
                                    name={"humidity"}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                    label={"Humidité"}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberField
                                    asString
                                    name={"impurityGreenGrain"}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                    label={"Impureté grains verts"}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberField
                                    asString
                                    name={"impuritySproutedGrain"}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                    label={"Impureté grains germés"}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <NumberField
                                    asString
                                    name={"impurityOther"}
                                    inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                                    label={"Impuretés autres"}
                                />
                            </Grid>
                            <Quantity catalog={catalog}/>
                            <Grid item xs={12} md={6}/>
                            <Grid item xs={12} md={12}>
                                <AutocompleteAsync
                                    name={"member"}
                                    label={"Adhérent..."}
                                    filterName={"company"}
                                    request={memberService.getAllMembers}
                                    getOptionLabel={(option) => `${option.company} ${option.code}`}
                                    isOptionEqualToValue={(option, value) =>
                                        option["@id"] === value["@id"]
                                    }
                                    value={memberSelected}
                                    hydra
                                />
                            </Grid>
                        </Grid>
                    </FormDisabler>
                </Form>
            </Formik>
        </>
    );
};

export default ChenevisPage;
