import {Box, Button as MuiButton, Button, DialogContent, DialogTitle, Grid} from "@mui/material";
import {TextField} from "@mui/material";
import {useState} from "react";
import {run} from "../../utils/services";
import snackBar from "../../components/SnackBar";
import {hydraService} from "../../utils/hydra";
import {useDialog} from "../../feature/DialogProvider";

const ModificationComment = ({data, setData, request = null}) => {
    const [comment, setComment] = useState(data.modificationComment);
    const [, closeDialog] = useDialog();

    const handleChangeText = (e) => {
        setComment(e.target.value);
    }
    const handleRegister = (e) => {
        const values = {...data, modificationComment: comment};
        run(request, {id: hydraService.getIdFromIri(values), ...values}, {})
            .then(() => {
                closeDialog();
                snackBar("Enregistrement effectué");
                setData(values, false);
            });
    }

    return (
        <>
            <DialogTitle className={'headerModal'}>
                <div><h3>Commentaire de modification</h3></div>
            </DialogTitle>
            <DialogContent className="contentModal">
                <Box sx={{my: 2}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {request ?
                                <TextField
                                    label={'Commentaire'}
                                    multiline
                                    fullWidth
                                    variant={'outlined'}
                                    rows={4}
                                    defaultValue={comment}
                                    onChange={handleChangeText}
                                />
                                : comment.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })}

                        </Grid>
                        {request &&
                            <Grid item xs={12}>
                                <Button variant="contained"
                                        color="secondary"
                                        onClick={handleRegister}
                                >
                                    Enregistrer
                                </Button>
                                <Button onClick={closeDialog}>Annuler</Button>
                            </Grid>}
                    </Grid>
                </Box>
            </DialogContent>
        </>
    );
}

export default ModificationComment;