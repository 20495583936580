import ProtectedComponent from "../../feature/ProtectedComponent";
import {ROLES} from "../../constants/roles";
import Button from "../../components/Formik/SubmitButton";
import {Button as MuiButton, Stack} from "@mui/material";
import ModalDelete from "../parameters/referentials/ModalDelete";
import {useNavigate, useParams} from "react-router-dom";
import {run} from "../../utils/services";
import {PARAMETERS} from "../../constants/parameters";
import snackBar from "../../components/SnackBar";
import {useFormikContext} from "formik";
import BackButton from "../../components/BackButton";
import PrintIcon from "@mui/icons-material/Print";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import IconButton from "@mui/material/IconButton";
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import {useDialog} from "../../feature/DialogProvider";
import ModificationComment from "./ModificationComment";

const NavigationButtons = ({putRequest, exportRequest, removeRequest}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {values, setValues} = useFormikContext();
    const [openDialog] = useDialog();
    const handleDelete = () => {
        run(removeRequest, {id}).then(() => {
            snackBar('Suppression effectuée');
            navigate(PARAMETERS.BACK_ROUTE);
            return;
        });
    }

    const handlePrint = () => {
        const data = {
            "format": "fiche",
            "number": null,
            "year": values.harvestYear,
            "harvest_year": values.harvestYear,
            "date_start": null,
            "date_end": null,
            "from_member_id": values.member,
            "to_member_id": values.member,
            "silo_id": null,
            "article_id": values.article,
            "from_number_id": values['@id'],
            "to_number_id": values['@id'],
            "article_type": null,
            "deposit": null
        }
        run(exportRequest, data)
            .then((response) => {
                snackBar(`Génération en cours...`, 'success');
                return;
            });
    }

    return (
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
            {values.modificationComment ? (
                <IconButton aria-label="delete"
                            color={'primary'}
                            onClick={() => {
                                openDialog({
                                    children: <ModificationComment data={values}/>
                                })
                            }}
                >
                    <SpeakerNotesIcon/>
                </IconButton>
            ) : (
                <IconButton aria-label="delete" sx={{'display': (values.modificationComment === undefined ? 'none' : '')}}>
                    <SpeakerNotesOffIcon/>
                </IconButton>
            )}
            <MuiButton variant={'outlined'}
                       startIcon={<PrintIcon/>}
                       onClick={handlePrint}
            >Imprimer</MuiButton>
            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                <Button variant={'outlined'} disabled={values.blocked}/>
                {id && <ModalDelete handleDelete={handleDelete} sx={null} startIcon={null}
                                    disabled={!values.canBeRemove}/>}
            </ProtectedComponent>
            <BackButton/>
        </Stack>
    );
}

export default NavigationButtons;