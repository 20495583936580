import {
    Box, Button as MuiButton, DialogActions, DialogContent, DialogTitle,
    Grid,
    MenuItem
} from "@mui/material";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {ticketService} from "../../../services/ticket.service";
import {ROLES} from "../../../constants/roles";
import {run} from "../../../utils/services";
import {exportService} from "../../../services/export.service";
import snackBar from "../../../components/SnackBar";
import {useDialog} from "../../../feature/DialogProvider";
import {Form, Formik} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";

const ExportDialog = ({catalog, isGranted, currentUser, title}) => {

    const [, closeDialog] = useDialog();

    const INITIAL_FORM_STATE = {
        format: "fiche",
        article_id: null,
        harvest_year: null,
        from_number_id: null,
        to_number_id: null,
        from_member_id: isGranted(ROLES.MEMBER) ? `/members/${currentUser.id}` : null,
        to_member_id: isGranted(ROLES.MEMBER) ? `/members/${currentUser.id}` : null,
        date_start: null,
        date_end: null,
        is_member: isGranted(ROLES.MEMBER)
    }

    let formatArray = [{
        value: 'fiche',
        label: 'Fiche'
    }, {
        value: 'liste',
        label: 'Liste'
    }, {
        value: 'fabrication',
        label: 'Fabrication'
    }, {
        value: 'livraison',
        label: 'Livraison'
    }, {
        value: 'reste_a_livrer',
        label: 'Etat du reste à livrer',
    }, {
        value: 'valorisation',
        label: 'Valorisation des pailles travaillées',
    }];
    if (isGranted(ROLES.ADMIN, ROLES.ACCOUNTANT)) {
        formatArray = formatArray.concat([{
            value: 'livraisons_non_traitees',
            label: 'Livraisons non traitées',
        }]);
    }

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                if (['reste_a_livrer', 'valorisation'].includes(values.format)) {
                    values.from_number_id = null;
                    values.to_number_id = null;
                }
                run(exportService.exportStrawTicket, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar(`Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.`, 'success');
                        return;
                    });
            }}
        >
            {({values}) => (
                <Form>
                    <DialogTitle className={title ? "headerModal" : null}>
                        <div><h3>{title}</h3></div>
                    </DialogTitle>
                    <DialogContent className="contentModal">
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3} className="Grid-container">
                                {isGranted(ROLES.ADMIN, ROLES.MEMBER) && <>
                                    <Grid item xs={12} md={12} className="Grid-grid">
                                        <Select name={'format'} label={'Format de l\'export'}>
                                            {formatArray.sort((a, b) => a.label.localeCompare(b.label)).map((el, i) => {
                                                return <MenuItem key={i} value={el.value}>{el.label}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                </>}
                                <Grid item xs={12} md={6} className="Grid-grid">
                                    <Select name={'article_id'} label={'Article'} nullIfEmpty>
                                        <MenuItem value={''}><em>Aucune</em></MenuItem>
                                        {catalog.articles.map((el, i) => {
                                            return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6} className="Grid-grid">
                                    <SelectYear
                                        name={'harvest_year'}
                                        label={'Année'}
                                        start={isGranted(ROLES.MEMBER) ? 2023 : 2021}
                                        reverse
                                        nullIfEmpty
                                    />
                                </Grid>
                                {isGranted(ROLES.ADMIN, ROLES.MEMBER) && <>
                                    {['reste_a_livrer', 'valorisation'].includes(values.format) === false && <>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            <AutocompleteAsync
                                                name={'from_number_id'}
                                                label={'Du numéro...'}
                                                filterName={'number'}
                                                request={ticketService.getAllStrawTicket}
                                                getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.harvestYear}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                hydra
                                                className="Autocomplete"
                                                nullIfEmpty
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} className="Grid-grid">
                                            <AutocompleteAsync
                                                name={'to_number_id'}
                                                label={'Au numéro...'}
                                                filterName={'number'}
                                                request={ticketService.getAllStrawTicket}
                                                getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.harvestYear}`}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                hydra
                                                className="Autocomplete"
                                                nullIfEmpty
                                            />
                                        </Grid>
                                    </>}
                                </>}
                                {isGranted(ROLES.ADMIN) && <>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'from_member_id'}
                                            label={'De l\'adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[company]': 'ASC'}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'to_member_id'}
                                            label={'A l\'adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                </>}
                                {values.format !== 'valorisation' ? (
                                    <>
                                        <Grid item xs={12} md={6}>
                                        <DateNative name={'date_start'} label={'Du (date livraison)'}
                                                    nullIfEmpty/>
                                    </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'date_end'} label={'Au (date livraison)'} nullIfEmpty/>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'date_start'} label={'Du (date fabrication)'}
                                                        nullIfEmpty/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DateNative name={'date_end'} label={'Au (date fabrication)'} nullIfEmpty/>
                                        </Grid>
                                    </>
                                )}

                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default ExportDialog;