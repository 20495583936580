import {
    Box, Button as MuiButton, DialogActions, DialogContent, DialogTitle,
    Grid,
    MenuItem
} from "@mui/material";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {run} from "../../../utils/services";
import {exportService} from "../../../services/export.service";
import snackBar from "../../../components/SnackBar";
import download from "../../../components/Download";
import {Form, Formik} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {useDialog} from "../../../feature/DialogProvider";
import {ROLES} from "../../../constants/roles";
import {supplyInvoiceService} from "../../../services/supplyInvoice.service";
import Radio from "../../../components/Formik/Radio";


const ExportDialog = ({catalog, title, isGranted}) => {
    const [, closeDialog] = useDialog();

    const availableInvoiceTypes = [
        "Liste des factures",
        "Factures Appro"
    ];

    const INITIAL_FORM_STATE = {
        filters: null,
        exportType: null,
        from_invoice_id: null,
        to_invoice_id: null,
        from_member_id: null,
        to_member_id: null,
        date_start: null,
        date_end: null,
        year: null,
        article_id: null,
        article_type_id: null
    };

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(exportService.exportSupplyInvoice, values, {setFieldError, setSubmitting})
                    .then((response) => {
                        snackBar(`Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.`, 'success');
                        return;
                    });
            }}
        >
            {({values}) => (
                <Form>
                    <DialogTitle className={title ? "headerModal" : null}>
                        <div><h3>{title}</h3></div>
                    </DialogTitle>
                    <DialogContent className="contentModal">
                        <Box sx={{my: 2}}>
                            <Grid container spacing={3}>
                                {isGranted(ROLES.ADMIN) && <>
                                    <Grid item xs={12}>
                                        <Radio
                                            label={'Type d\'export'}
                                            name={`filters`}
                                            options={catalog?.exportInvoicesTypes
                                                ?.filter(el => availableInvoiceTypes.includes(el))
                                                .map(el => ({value: el, label: el}))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'from_invoice_id'}
                                            label={'De la facture n°'}
                                            filterName={'number'}
                                            request={supplyInvoiceService.getAllSupplyInvoices}
                                            getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[number]': 'ASC'}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'to_invoice_id'}
                                            label={'A la facture n°'}
                                            filterName={'number'}
                                            request={supplyInvoiceService.getAllSupplyInvoices}
                                            getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.reference}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[number]': 'DESC', 'reverse': true}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'from_member_id'}
                                            label={'De l\'adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[company]': 'ASC'}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteAsync
                                            name={'to_member_id'}
                                            label={'A l\'adhérent...'}
                                            filterName={'company'}
                                            request={memberService.getAllMembers}
                                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                            filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                            hydra
                                            className="Autocomplete"
                                            nullIfEmpty
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateNative name={'date_start'} label={'Du...'} nullIfEmpty/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateNative name={'date_end'} label={'Au...'} nullIfEmpty/>
                                    </Grid>
                                </>}
                                <Grid item xs={12} md={6}>
                                    <SelectYear
                                        name={'year'}
                                        label={'Année'}
                                        reverse
                                        start={isGranted(ROLES.MEMBER) ? 2023 : 2021}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select name={'article_type_id'}
                                            label={'Type d\'article'}
                                            disabled={values?.articles?.length > 0}
                                    >
                                        <MenuItem value={null}><em>Aucun</em></MenuItem>
                                        {catalog.articleTypeList.map((el, i) => (
                                            <MenuItem key={i} value={el['@id']}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions className="footerModal">
                        <SubmitButton>
                            Générer
                        </SubmitButton>
                        <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}

export default ExportDialog;