import {
    Box, Button as MuiButton, DialogActions, DialogContent, DialogTitle,
    Grid,
    MenuItem
} from "@mui/material";
import Select from "../../../components/Formik/Select";
import DateNative from "../../../components/Formik/Date/Native";
import {memberService} from "../../../services/member.service";
import AutocompleteAsync from "../../../components/Formik/AutocompleteAsync";
import SelectYear from "../../../components/Formik/SelectYear";
import {run} from "../../../utils/services";
import {exportService} from "../../../services/export.service";
import snackBar from "../../../components/SnackBar";
import {useDialog} from "../../../feature/DialogProvider";
import {Form, Formik} from "formik";
import SubmitButton from "../../../components/Formik/SubmitButton";
import {ticketService} from "../../../services/ticket.service";
import {ROLES} from "../../../constants/roles";


const ExportDialog = ({catalog, isGranted, currentUser, title}) => {

    const [, closeDialog] = useDialog();

    const INITIAL_FORM_STATE = {
        from_number_id: null,
        to_number_id: null,
        date_start: null,
        date_end: null,
        from_member_id: isGranted(ROLES.MEMBER) ? `/members/${currentUser.id}` : null,
        to_member_id: isGranted(ROLES.MEMBER) ? `/members/${currentUser.id}` : null,
        harvest_year: new Date().getFullYear(),
        article_type: null,
        deposit: null,
    }

    return (
        <Formik
            validateOnChange={false}
            initialValues={INITIAL_FORM_STATE}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                run(exportService.exportSupplyTicket, values, {setFieldError, setSubmitting})
                    .then(() => {
                        snackBar(`Votre document est en cours de génération, une pop-up s’ouvrira une fois celui-ci disponible et vous pourrez le télécharger via celle-ci. Un mail vous sera également envoyé avec le document en pièce-jointe.`, 'success');
                        return;
                    });
            }}
        >
            <Form>
                <DialogTitle className={title ? "headerModal" : null}>
                    <div><h3>{title}</h3></div>
                </DialogTitle>
                <DialogContent className="contentModal">
                    <Box sx={{my: 2}}>
                        <Grid container spacing={3} className="Grid-container">
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <AutocompleteAsync
                                    name={'from_number_id'}
                                    label={'Du numéro...'}
                                    filterName={'number'}
                                    request={ticketService.getAllSupplyTicket}
                                    getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.harvestYear}`}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    filtersOnLoading={{'order[company]': 'ASC'}}
                                    hydra
                                    className="Autocomplete"
                                    nullIfEmpty
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <AutocompleteAsync
                                    name={'to_number_id'}
                                    label={'Au numéro...'}
                                    filterName={'number'}
                                    request={ticketService.getAllSupplyTicket}
                                    getOptionLabel={(option) => `${option.number} - ${option.member.company} - ${option.harvestYear}`}
                                    isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                    filtersOnLoading={{'order[company]': 'ASC'}}
                                    hydra
                                    className="Autocomplete"
                                    nullIfEmpty
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateNative name={'date_start'} label={'Du...'} nullIfEmpty/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateNative name={'date_end'} label={'Au...'} nullIfEmpty/>
                            </Grid>
                            {isGranted(ROLES.ADMIN) &&
                                <><Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'from_member_id'}
                                        label={'De l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'ASC'}}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid><Grid item xs={12} md={6}>
                                    <AutocompleteAsync
                                        name={'to_member_id'}
                                        label={'A l\'adhérent...'}
                                        filterName={'company'}
                                        request={memberService.getAllMembers}
                                        getOptionLabel={(option) => `${option.company} ${option.code}`}
                                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                        filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                                        hydra
                                        className="Autocomplete"
                                        nullIfEmpty
                                    />
                                </Grid></>}
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <SelectYear
                                    name={'harvest_year'}
                                    label={'Année'}
                                    reverse
                                    nullIfEmpty
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <Select name={'article_type'} label={'Type d\'article'} nullIfEmpty>
                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                    {catalog.articleTypeList?.map((el, i) => {
                                        return <MenuItem key={i}
                                                         value={el['@id']}>{el.label}</MenuItem>
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} className="Grid-grid">
                                <Select name={'deposit'} label={'Dépôt'} nullIfEmpty>
                                    <MenuItem value={''}><em>Aucun</em></MenuItem>
                                    {catalog.depositList?.map((el, i) => {
                                        return <MenuItem key={i}
                                                         value={el.name}>{el.name}</MenuItem>
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions className="footerModal">
                    <SubmitButton>
                        Générer
                    </SubmitButton>
                    <MuiButton onClick={closeDialog}>Annuler</MuiButton>
                </DialogActions>
            </Form>
        </Formik>
    );
}

export default ExportDialog;