import { ENDPOINTS } from "../constants/endpoints";
import axios from "../utils/axios";
import { handleResponse } from "../utils/services";

class InvoiceService {
  async getAllComptabilisationInvoices(parameters) {
    const queryString = (new URLSearchParams(parameters)).toString();
    return axios
        .get(ENDPOINTS.CHENEVIS_COMPTABILISATION + (queryString ? `?${queryString}` : ''))
        .then(handleResponse);
  }

  async generateSage(parameters) {
    return axios
        .post(ENDPOINTS.INVOICE_SAGE, parameters)
        .then(handleResponse);
  }

  async generateSageEdition(parameters) {
    return axios
        .post(ENDPOINTS.INVOICE_SAGE_EDITION, parameters, {
          responseType: 'blob'
        })
        .then(handleResponse);
  }
}

export const invoiceService = new InvoiceService();
